exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".download_file-name_1tb8X {\n    font-family: monospace;\n    -webkit-user-select: text;\n       -moz-user-select: text;\n        -ms-user-select: text;\n            user-select: text;\n    word-wrap: break-word;\n}\n.download_file-name_1tb8X::before {\n    content: '\"';\n}\n.download_file-name_1tb8X::after {\n    content: '\"';\n}\n.download_name_3QMMC {\n\n}\n.download_dot_Tzo59 {\n\n}\n.download_extension_359jK {\n    text-decoration: underline;\n}\n", ""]);

// exports
exports.locals = {
	"file-name": "download_file-name_1tb8X",
	"fileName": "download_file-name_1tb8X",
	"name": "download_name_3QMMC",
	"dot": "download_dot_Tzo59",
	"extension": "download_extension_359jK"
};